import { graphql, useStaticQuery } from 'gatsby';
import { usePageContext } from 'gatsby-theme-thepuzzlers-core';

export const useContactPageData = () => {
  const { locale } = usePageContext();
  const data = useStaticQuery(graphql`
    query {
      en: contactPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
          address {
            headline
          }
          contact {
            headline
          }
          socials {
            headline
          }
        }
        form {
          form {
            inputs {
              name {
                label
                placeholder
              }
              email {
                label
                placeholder
              }
              textarea {
                label
                placeholder
              }
            }
            checkBox {
              label
            }
            button
            messages {
              success {
                headline
                text
              }
              error {
                headline
                text
              }
            }
            privacyPolicyDisclaimer
          }
        }
      }
      de: deContactPageDataJson {
        seo {
          title
          description
          shortTitle
          shortDescription
          url
        }
        header {
          headline
          address {
            headline
          }
          contact {
            headline
          }
          socials {
            headline
          }
        }
        form {
          form {
            inputs {
              name {
                label
                placeholder
              }
              email {
                label
                placeholder
              }
              textarea {
                label
                placeholder
              }
            }
            checkBox {
              label
            }
            button
            messages {
              success {
                headline
                text
              }
              error {
                headline
                text
              }
            }
            privacyPolicyDisclaimer
          }
        }
      }
    }
  `);

  return data[locale];
};
