const transition = {
  type: 'tween',
  duration: 0.2,
  ease: 'easeInOut'
};

export const slideInAnimation = {
  initial: {
    opacity: 0,
    y: 40,
    transition
  },
  animate: {
    opacity: 1,
    y: 0,
    transition
  },
  exit: {
    opacity: 0,
    y: 40,
    transition
  }
};
