import React from 'react';

// External Components
import { Box, Label, Input, TextArea, Paragraph } from '@thepuzzlers/pieces';
import { AnimatePresence } from 'framer-motion';
import { Checkbox } from '@theme-ui/components';

// Local Components
import {
  TealNoiseSection,
  GridSpacer,
  HighlightedHeading,
  ButtonPrimary,
  Vector
} from 'components';

// Assets
import crown from 'assets/svg/crown_lime.svg';

// Animations
import { slideInAnimation } from './animations';

import { convertApiHtmlText } from 'graphqlHooks/helper';

// Form states
const WAITING_FOR_SUBMIT = 'waiting';
const SUBMITTING = 'submitting';
const ERROR = 'error';
const SUCCESS = 'success';

const formEndpoint =
  'https://getform.io/f/cc2e76a2-19c9-4e73-9073-85f3fbe20f3e';

export const Form = ({ staticData: { form }, data: { headline } }) => {
  return (
    // Markup
    <ContentWrapper>
      <Spacer position={0} />
      <Decoration />
      <Headline data={headline} />
      <FormContent data={form} />
      <Spacer position={1} />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <TealNoiseSection id="contact-page__form-section">
    {children}
  </TealNoiseSection>
);

const Headline = ({ data }) => (
  <HighlightedHeading
    as="h2"
    data={convertApiHtmlText(data.html)}
    sx={{
      color: 'background',
      fontSize: ['2.4rem', '2.7rem', '3rem', '2.4rem', '2.8rem', '3.2rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '2/span 17',
        '1/span 11',
        '2/span 8',
        '3/span 8'
      ],
      gridRow: 3,
      lineHeight: 1.25,
      mt: '0.8rem'
    }}
  />
);

const FormContent = ({ data: { inputs, button, messages, checkBox } }) => {
  // Store form submitting state
  const [formState, setFormState] = React.useState(WAITING_FOR_SUBMIT);
  // User consent state
  const [checkboxState, setCheckboxState] = React.useState(false);

  const handleCheckbox = () => setCheckboxState(!checkboxState);

  // Reset form state to default after 6 seconds
  const resetFormState = () => {
    setTimeout(() => {
      setFormState(WAITING_FOR_SUBMIT);
    }, 6000);
  };

  const handleServerResponse = (ok, form) => {
    if (ok) {
      setFormState(SUCCESS);
      // Remove chacked state from consent checkbox
      handleCheckbox();
      // Reset form to default after 4 seconds
      resetFormState();
      // Only rest form, if sending was successful
      form.reset();
    } else {
      setFormState(ERROR);
      // Reset form to default after 4 seconds
      resetFormState();
    }
  };

  // Handle responses on form submit
  const handleSubmit = (e) => {
    e.preventDefault();

    // If consent checkbox is not checked don't submit form
    if (!checkboxState) return;

    // Submit form
    setFormState(SUBMITTING);

    const form = e.target;
    const formData = new FormData(form);

    fetch(formEndpoint, {
      method: 'POST',
      body: formData
    })
      .then((response) => {
        // show loader a bit longer to avoid loading flash
        setTimeout(() => {
          if (response.ok) {
            handleServerResponse(true, form);
          } else {
            handleServerResponse(false, form);
          }
        }, 400);
      })
      .catch(() => {
        setTimeout(() => {
          handleServerResponse(false, form);
        }, 400);
      });
  };

  return (
    <FormWrapper onSubmit={handleSubmit}>
      <Name {...inputs.name} />
      <Email {...inputs.email} />
      <Textarea {...inputs.textarea} />
      <PrivacyPolicyCheckbox
        data={checkBox}
        checkboxState={checkboxState}
        handleCheckbox={handleCheckbox}
      />
      <SubmitButton data={button} disabled={formState === SUBMITTING} />
      <AnimatePresence>
        {(formState === SUCCESS || formState === ERROR) && (
          <Message
            data={formState === SUCCESS ? messages.success : messages.error}
          />
        )}
      </AnimatePresence>
    </FormWrapper>
  );
};

const FormWrapper = ({ children, ...props }) => (
  <Box
    as="form"
    sx={{
      display: 'grid',
      gap: ['4rem', '4.8rem', '4.6rem', '1.7rem', '2.6rem', '3rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '2/span 17',
        '13/span 11',
        '12/span 11',
        '14/span 8'
      ],
      gridRow: [4, 4, 4, 3, 3, 3],
      gridTemplateColumns: '1fr',
      mt: ['4rem', '3.8rem', '4.8rem', 0, 0, 0]
    }}
    {...props}>
    {children}
  </Box>
);

const Name = ({ label, placeholder }) => (
  <Box sx={{}}>
    <Label variant="forms.label">{label}</Label>
    <Input type="text" name="name" placeholder={placeholder} required />
  </Box>
);

const Email = ({ label, placeholder }) => (
  <Box sx={{ width: '100%' }}>
    <Label variant="forms.label">{label}</Label>
    <Input type="email" name="email" placeholder={placeholder} required />
  </Box>
);

const Textarea = ({ label, placeholder }) => (
  <Box sx={{ width: '100%' }}>
    <Label variant="forms.label">{label}</Label>
    <TextArea
      required
      name="message"
      placeholder={placeholder}
      rows={6}
      sx={{ resize: 'none' }}
    />
  </Box>
);

const SubmitButton = ({ data, disabled }) => (
  <ButtonPrimary
    type="submit"
    primary
    disabled={disabled}
    text={data}
    scribbleColor="background"
    sx={{
      alignSelf: 'start',
      justifySelf: 'start',
      mt: ['0.8rem', 0, '0.2rem', '2rem', '4.4rem', '4.4rem'],
      opacity: disabled ? 0.5 : 1
    }}
  />
);

const Message = ({ data: { headline, text } }) => (
  <Box
    className="form__message"
    // Animation values
    initial="initial"
    animate="animate"
    exit="exit"
    variants={slideInAnimation}>
    <Paragraph
      variant="forms.label"
      dangerouslySetInnerHTML={{
        __html: headline
      }}
      sx={{ fontFamily: 'primary.bold' }}
    />
    <Paragraph
      variant="forms.label"
      dangerouslySetInnerHTML={{
        __html: text
      }}
      sx={{ mt: '0.8rem' }}
    />
  </Box>
);

// Spacer and Decorations

const Decoration = () => (
  <Vector
    src={crown}
    alt=""
    sx={{
      gridRow: 2,
      left: ['15.9rem', '17.3rem', '22.7rem', '16.6rem', '22.7rem', '31.6rem'],
      position: 'relative',
      width: ['3rem', '3.3rem', '4.3rem', '3rem', '4.3rem', '5.9rem']
    }}
  />
);

const Spacer = ({ position }) => {
  const styles = [
    {
      gridRow: 1,
      height: ['16.2rem', '13.5rem', '9.8rem', '11.2rem', '19.9rem', '27.3rem']
    },
    {
      gridRow: 5,
      height: ['20.3rem', '27.6rem', '19.9rem', '17.9rem', '21.3rem', '29.5rem']
    }
  ];

  return <GridSpacer sx={{ ...styles[position] }} />;
};

const PrivacyPolicyCheckbox = ({
  data: { label },
  checkboxState,
  handleCheckbox
}) => {
  return (
    <Label
      htmlFor="privacyPolicy"
      sx={{
        display: 'flex',
        cursor: 'pointer',
        color: 'background',
        fontFamily: 'body.normal',
        lineHeight: 1.3,
        fontSize: ['1.3rem', '1.4rem', '1.4rem', '1.2rem', '1.3rem', '1.3rem'],
        '& a': {
          color: 'secondary',
          textDecoration: 'underline'
        }
      }}
      onClick={handleCheckbox}>
      <Checkbox checked={checkboxState} required name="privacyPolicy" />
      <Box
        as="span"
        sx={{
          display: 'inline'
        }}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </Label>
  );
};
