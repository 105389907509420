const transitionDefault = {
  type: 'tween',
  ease: 'easeOut'
};

export const revealHeadline = {
  initial: {
    opacity: 0,
    y: 40
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      ...transitionDefault,
      duration: 0.8
    }
  }
};

export const revealList = {
  initial: {
    opacity: 0,
    y: 40
  },
  animate: (custom) => ({
    opacity: 1,
    y: 0,
    transition: {
      ...transitionDefault,
      delay: custom,
      duration: 1
    }
  })
};

export const revealArrow = {
  initial: {
    scale: 0.6,
    opacity: 0,
  },
  animate: {
    scale: 1,
    opacity: 1,
    transition: {
      ...transitionDefault,
      duration: 0.4,
      delay: 1.5
    }
  }
};
