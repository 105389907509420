import React from 'react';

// External Components
import { Box, Heading, Link, Paragraph } from '@thepuzzlers/pieces';

// Local Components
import { TealNoiseSection, GridSpacer, ArrowDown } from 'components';

// Animations
import { revealHeadline, revealList, revealArrow } from './animations';

// Cms data
import { useKisBusinessContactData } from 'graphqlHooks';
import { convertApiHtmlText } from 'graphqlHooks/helper';

export const Header = ({
  data: { headline, address: address, contact, socials }
}) => {
  const {
    address: addressLocation,
    contact_links,
    social_links
  } = useKisBusinessContactData();

  return (
    // Markup
    <ContentWrapper>
      <Spacer position={0} />
      <Headline data={headline} />
      <Spacer position={1} />
      <Address data={address} location={addressLocation} />
      <Contact data={contact} links={contact_links} />
      <Socials data={socials} links={social_links} />
      <Arrow />
    </ContentWrapper>
  );
};

// Elements

const ContentWrapper = ({ children }) => (
  <TealNoiseSection
    as="header"
    id="contact-page__header"
    // Animation values
    initial="initial"
    animate="animate"
    sx={{ zIndex: 1 }}>
    {children}
  </TealNoiseSection>
);

const Headline = ({ data }) => (
  <Heading
    dangerouslySetInnerHTML={{ __html: data }}
    // Animation value
    variants={revealHeadline}
    sx={{
      color: 'background',
      fontFamily: 'primary.normal',
      fontSize: ['4.8rem', '6rem', '7.5rem', '3.8rem', '5.2rem', '6.4rem'],
      gridColumn: [
        '1/13',
        '1/span 11',
        '2/span 20',
        '4/span 18',
        '5/span 16',
        '5/span 16'
      ],
      lineHeight: 1.15,
      textTransform: 'uppercase',
      '& .lime-text': {
        animation: '0.8s ease-out 0.8s lightToLimeText',
        animationFillMode: 'forwards',
        fontFamily: 'primary.italic'
      },
      display: [null, null, null, 'flex', 'flex', 'flex'],
      flexDirection: [null, null, null, 'column', 'column', 'column'],
      '& > span:nth-of-type(2)': {
        ml: [null, null, null, 'auto', 'auto', 'auto']
      }
    }}
  />
);

const Address = ({ data: { headline, text }, location }) => (
  <ListWrapper
    // Animation values
    custom={0.4}
    variants={revealList}
    sx={{
      gridColumn: [
        '1/span 9',
        '1/span 6',
        '2/span 9',
        '1/span 8',
        '2/span 7',
        '3/span 5'
      ]
    }}>
    <ListHeadline data={headline} />
    <Paragraph
      variant="links.contact"
      dangerouslySetInnerHTML={{
        __html: convertApiHtmlText(location.html)
      }}
      sx={{
        cursor: 'auto'
      }}
    />
  </ListWrapper>
);

const Contact = ({ data: { headline }, links }) => (
  <RegularLinkList
    headline={headline}
    links={links}
    // Animation values
    custom={0.5}
    variants={revealList}
    sx={{
      gridColumn: [
        '1/span 7',
        '8/13',
        '13/span 8',
        '10/span 7',
        '10/span 6',
        '10/span 5'
      ],
      mt: ['5.6rem', 0, 0, 0, 0, 0]
    }}
  />
);

const Socials = ({ data: { headline }, links }) => (
  <RegularLinkList
    headline={headline}
    links={links}
    // Animation values
    custom={0.6}
    variants={revealList}
    sx={{
      gridColumn: [
        '1/span 5',
        '1/span 4',
        '2/span 5',
        '18/span 5',
        '17/span 4',
        '17/span 3'
      ],
      mt: ['5.6rem', '5.4rem', '8.3rem', 0, 0, 0]
    }}
  />
);

const Arrow = () => (
  <ArrowDown
    // Animation value
    variants={revealArrow}
    sx={{
      color: 'secondary',
      gridColumn: [
        '10/span 2',
        '9/span 2',
        '18/span 3',
        '23/25',
        '22/span 2',
        '21/span 2'
      ],
      gridRow: ['5/span 2', 5, 5, '3/span 2', '3/span 2', '3/span 2'],
      justifySelf: ['center', 'center', 'end', 'end', 'end', 'end'],
      mt: ['11.5rem', '10rem', '12rem', '3.1rem', '10.7rem', '9.3rem'],
      width: ['3.2rem', '4rem', '5.4rem', '3rem', '3.6rem', '4.2rem']
    }}
  />
);

// Reusable Components

const ListWrapper = ({ children, sx, ...props }) => (
  <Box
    className="contact__list-wrapper"
    sx={{
      alignSelf: 'start',
      display: 'grid',
      gap: ['2rem', '1.6rem', '2.1rem', '2.3rem', '2.1rem', '2.1rem'],
      gridRow: [null, null, null, 4, 4, 4],
      ...sx
    }}
    {...props}>
    {children}
  </Box>
);

const RegularLinkList = ({ headline, links, sx, ...props }) => (
  <ListWrapper sx={sx} {...props}>
    <ListHeadline data={headline} />
    <Box
      className="link-list-wrapper"
      sx={{
        display: 'grid',
        gap: [0, '0.8rem', '1.6rem', '1.6rem', '1.6rem', '1.6rem']
      }}>
      {links.map((link) => (
        <Link
          key={link.link_text}
          href={link.link_to}
          variant="contact"
          target="_blank"
          rel="noreferrer noopener">
          {link.link_text}
        </Link>
      ))}
    </Box>
  </ListWrapper>
);

const ListHeadline = ({ data }) => (
  <Heading
    as="h4"
    sx={{
      color: 'background',
      fontFamily: 'body.normal',
      fontSize: ['1.4rem', '1.6rem', '1.6rem', '1.4rem', '1.6rem', '1.6rem'],
      lineHeight: 1
    }}>
    {data}
  </Heading>
);

// Spacer

const Spacer = ({ position }) => {
  const styles = [
    {
      height: ['11.2rem', '19.1rem', '27.3rem', '14.7rem', '26rem', '26.3rem']
    },
    {
      gridRow: [null, null, null, 3, 3, 3],
      height: ['5.6rem', '13rem', '11.9rem', '11rem', '19.7rem', '20.3rem']
    }
  ];

  return <GridSpacer sx={{ ...styles[position] }} />;
};
