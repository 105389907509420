import React from 'react';

import { SEO } from 'gatsby-theme-thepuzzlers-intl';
import { injectIntl } from 'gatsby-theme-thepuzzlers-intl';

// Local Components
import { useContactPageData, Header, Form } from 'sections/contactPage';

// External Data
import { useKisContactPageData } from 'graphqlHooks';

const Contact = () => {
  const { seo, header, form } = useContactPageData();

  const { form: cmsForm } = useKisContactPageData();

  return (
    <>
      <SEO {...seo} />
      <Header data={header} />
      <Form staticData={form} data={cmsForm} />
    </>
  );
};

export default injectIntl(Contact);
